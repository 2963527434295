import "bootstrap/js/src/offcanvas";
import "bootstrap/js/src/collapse";
import "./store/anchor-link";
import "./store/quick-search";
import "./store/topbar-popup";
import "./store/mainmenu";
import "./store/filters-pagination";
import "./store/cart-button";
import "./store/favourite-icon";
import "./store/quick-view";
import "./store/visualizer";
import "./store/product";
import "./store/product-navbar";
import "./store/product-rating";
import "./store/cart";
import "./store/placemap";
import "./store/sticky";
import "./store/articles";
import "./store/link-out";
import "./store/newsletter";

//https://github.com/rails/rails/tree/main/actionview/app/assets/javascripts/rails-ujs
import Rails from "@rails/ujs";
Rails.start();
